var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pr-2 pl-2"},[(!_vm.editMode)?_c('div',{staticClass:"d-flex"},[(!_vm.isDraft)?_c('div',{staticClass:"mr-2 mt-1"},[(_vm.isActual)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":""}},'v-icon',attrs,false),on),[_vm._v("mdi-alpha-r-circle")])]}}],null,false,3042713597)},[_c('span',[_vm._v("Realizado")])]):_vm._e(),(_vm.isProyected)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":""}},'v-icon',attrs,false),on),[_vm._v("mdi-alpha-p-circle-outline")])]}}],null,false,439099794)},[_c('span',[_vm._v("Proyectado")])]):_vm._e()],1):_vm._e(),_c('v-text-field',{staticClass:"managrx-input mr-2",attrs:{"value":"Fletes","label":"Familia","outlined":"","dense":"","readonly":"","filled":""}}),_c('v-text-field',{staticClass:"managrx-input mr-2",attrs:{"value":_vm.dynamicYield,"label":"Rinde Dinámico","outlined":"","dense":"","readonly":"","filled":""}}),_c('v-text-field',{staticClass:"managrx-input mr-2",attrs:{"value":_vm.assignedHectares,"label":"Hectáreas Asignadas","outlined":"","dense":"","readonly":"","filled":""}}),_c('v-text-field',{staticClass:"managrx-input mr-2",attrs:{"value":_vm.producedTons.toLocaleString('en-US', {
      timeZone: 'America/Argentina/Buenos_Aires',
    }),"label":"Toneladas Producidas","outlined":"","dense":"","readonly":"","filled":""}}),_c('v-text-field',{staticClass:"managrx-input mr-2",attrs:{"value":_vm.ownTons.toLocaleString('en-US', {
      timeZone: 'America/Argentina/Buenos_Aires',
    }),"label":"Toneladas Propias","outlined":"","dense":"","readonly":"","filled":""}}),_c('v-text-field',{staticClass:"managrx-input mr-2",attrs:{"value":_vm.freightCostTn,"label":"Costo Flete Tn","outlined":"","dense":"","readonly":"","filled":""}}),_c('v-text-field',{staticClass:"managrx-input mr-2",attrs:{"value":_vm.subTotal,"label":"Costo Flete","outlined":"","dense":"","readonly":"","filled":""}}),(!_vm.deleteMode)?_c('v-btn',{staticClass:"mr-2",attrs:{"depressed":true,"color":"warning white--text"},on:{"click":_vm.editClick}},[_c('v-icon',[_vm._v(" mdi-pencil")])],1):_vm._e()],1):_vm._e(),(_vm.editMode)?_c('div',{staticClass:"d-flex"},[(!_vm.isDraft)?_c('div',{staticClass:"mr-2 mt-1"},[(_vm.isActual)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":""}},'v-icon',attrs,false),on),[_vm._v("mdi-alpha-r-circle")])]}}],null,false,3042713597)},[_c('span',[_vm._v("Realizado")])]):_vm._e(),(_vm.isProyected)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":""}},'v-icon',attrs,false),on),[_vm._v("mdi-alpha-p-circle-outline")])]}}],null,false,439099794)},[_c('span',[_vm._v("Proyectado")])]):_vm._e()],1):_vm._e(),_c('v-text-field',{staticClass:"managrx-input mr-2",attrs:{"value":"Fletes","label":"Familia","outlined":"","dense":"","readonly":"","filled":""}}),_c('v-text-field',{staticClass:"managrx-input mr-2",attrs:{"value":_vm.dynamicYield,"label":"Rinde Dinámico","outlined":"","dense":"","readonly":"","filled":""}}),_c('v-text-field',{staticClass:"managrx-input mr-2",attrs:{"value":_vm.assignedHectares,"label":"Hectáreas Asignadas","outlined":"","dense":"","readonly":"","filled":""}}),_c('v-text-field',{staticClass:"managrx-input mr-2",attrs:{"value":_vm.producedTons.toLocaleString('en-US', {
      timeZone: 'America/Argentina/Buenos_Aires',
    }),"label":"Toneladas Producidas","outlined":"","dense":"","readonly":"","filled":""}}),_c('v-text-field',{staticClass:"managrx-input mr-2",attrs:{"value":_vm.ownTons.toLocaleString('en-US', {
      timeZone: 'America/Argentina/Buenos_Aires',
    }),"label":"Toneladas Propias","outlined":"","dense":"","readonly":"","filled":""}}),_c('v-text-field',{staticClass:"managrx-input mr-2",attrs:{"type":"number","label":"Costo Flete Tn","outlined":"","dense":""},model:{value:(_vm.newFreightCostTn),callback:function ($$v) {_vm.newFreightCostTn=$$v},expression:"newFreightCostTn"}}),_c('v-text-field',{staticClass:"managrx-input mr-2",attrs:{"value":_vm.subTotal,"label":"Costo Flete","outlined":"","dense":"","readonly":"","filled":""}}),(!_vm.isDraft)?_c('v-select',{staticClass:"managrx-input mr-2",attrs:{"outlined":"","dense":"","label":"Estado","open-on-clear":"","persistent-hint":"","hint":_vm.newActualStatus !== _vm.isActualFreightCostTn
        ? 'El estado fue modificado'
        : '',"items":_vm.actualsItems,"item-value":"value","item-text":"name"},model:{value:(_vm.newActualStatus),callback:function ($$v) {_vm.newActualStatus=$$v},expression:"newActualStatus"}}):_vm._e(),(!_vm.deleteMode)?_c('v-btn',{staticClass:"mr-2",attrs:{"depressed":true,"color":"primary white--text"},on:{"click":_vm.saveClick}},[_c('v-icon',[_vm._v(" mdi-floppy")])],1):_vm._e(),(!_vm.deleteMode)?_c('v-btn',{staticClass:"mr-2",attrs:{"depressed":true,"color":"warning white--text"},on:{"click":_vm.backClick}},[_vm._v(" Atrás ")]):_vm._e()],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }