<template>
  <div class="pr-2 pl-2">
    <div class="d-flex" v-if="!editMode">
      <div class="mr-2 mt-1" v-if="!isDraft">
        <v-tooltip v-if="isActual" top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="" v-bind="attrs" v-on="on">mdi-alpha-r-circle</v-icon>
          </template>
          <span>Realizado</span>
        </v-tooltip>
        <v-tooltip v-if="isProyected" top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="" v-bind="attrs" v-on="on">mdi-alpha-p-circle-outline</v-icon>
          </template>
          <span>Proyectado</span>
        </v-tooltip>
      </div>
      <v-text-field value="Fletes" label="Familia" class="managrx-input mr-2" outlined dense readonly
        filled></v-text-field>
      <v-text-field :value="dynamicYield" label="Rinde Dinámico" class="managrx-input mr-2" outlined dense readonly
        filled></v-text-field>

      <v-text-field :value="assignedHectares" label="Hectáreas Asignadas" class="managrx-input mr-2" outlined dense
        readonly filled></v-text-field>
      <v-text-field :value="producedTons.toLocaleString('en-US', {
        timeZone: 'America/Argentina/Buenos_Aires',
      })
        " label="Toneladas Producidas" class="managrx-input mr-2" outlined dense readonly filled></v-text-field>
      <v-text-field :value="ownTons.toLocaleString('en-US', {
        timeZone: 'America/Argentina/Buenos_Aires',
      })
        " label="Toneladas Propias" class="managrx-input mr-2" outlined dense readonly filled></v-text-field>
      <v-text-field :value="freightCostTn" label="Costo Flete Tn" class="managrx-input mr-2" outlined dense readonly
        filled></v-text-field>

      <v-text-field :value="subTotal" label="Costo Flete" class="managrx-input mr-2" outlined dense readonly
        filled></v-text-field>

      <v-btn :depressed="true" v-if="!deleteMode" color="warning white--text" class="mr-2" @click="editClick">
        <v-icon> mdi-pencil</v-icon>
      </v-btn>
    </div>

    <div class="d-flex" v-if="editMode">
      <div class="mr-2 mt-1" v-if="!isDraft">
        <v-tooltip v-if="isActual" top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="" v-bind="attrs" v-on="on">mdi-alpha-r-circle</v-icon>
          </template>
          <span>Realizado</span>
        </v-tooltip>
        <v-tooltip v-if="isProyected" top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="" v-bind="attrs" v-on="on">mdi-alpha-p-circle-outline</v-icon>
          </template>
          <span>Proyectado</span>
        </v-tooltip>
      </div>
      <v-text-field value="Fletes" label="Familia" class="managrx-input mr-2" outlined dense readonly
        filled></v-text-field>
      <v-text-field :value="dynamicYield" label="Rinde Dinámico" class="managrx-input mr-2" outlined dense readonly
        filled></v-text-field>
      <v-text-field :value="assignedHectares" label="Hectáreas Asignadas" class="managrx-input mr-2" outlined dense
        readonly filled></v-text-field>
      <v-text-field :value="producedTons.toLocaleString('en-US', {
        timeZone: 'America/Argentina/Buenos_Aires',
      })
        " label="Toneladas Producidas" class="managrx-input mr-2" outlined dense readonly filled></v-text-field>
      <v-text-field :value="ownTons.toLocaleString('en-US', {
        timeZone: 'America/Argentina/Buenos_Aires',
      })
        " label="Toneladas Propias" class="managrx-input mr-2" outlined dense readonly filled></v-text-field>
      <v-text-field v-model="newFreightCostTn" type="number" label="Costo Flete Tn" class="managrx-input mr-2" outlined
        dense></v-text-field>

      <v-text-field :value="subTotal" label="Costo Flete" class="managrx-input mr-2" outlined dense readonly
        filled></v-text-field>
      <v-select v-if="!isDraft" outlined class="managrx-input mr-2" dense label="Estado" open-on-clear persistent-hint
        :hint="newActualStatus !== isActualFreightCostTn
          ? 'El estado fue modificado'
          : ''
          " :items="actualsItems" v-model="newActualStatus" item-value="value" item-text="name">
      </v-select>
      <v-btn :depressed="true" v-if="!deleteMode" color="primary white--text" class="mr-2" @click="saveClick">
        <v-icon> mdi-floppy</v-icon>
      </v-btn>
      <v-btn :depressed="true" v-if="!deleteMode" color="warning white--text" class="mr-2" @click="backClick">
        Atrás
      </v-btn>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.background-muted {
  background-color: #fafafa;
  opacity: 0.3;
}

.v-text-field {
  width: 400px;
}

.managrx-input {
  font-weight: 400 !important;
}
</style>

<script>
import { HARVEST_FREIGHT_ACTUAL_ITEMS } from "../../managrx/constants";
import { roundToX } from "../../managrx/utils";
export default {
  name: "AbstractFreightCostInput",
  props: {
    isDraft: Boolean,
    isActualFreightCostTn: Boolean,
    assignedHectares: Number,
    activitySliceId: Number,
    dynamicYield: Number,
    freightCostTn: Number,
    fixedShareCroppingValue: Number,
    variableShareCroppingValue: Number,
    isFixedSharecroppingHandedOver: Boolean,
    isVariableSharecroppingHandedOver: Boolean,
  },
  methods: {
    deleteClick() {
      this.deleteMode = true;
    },
    saveClick() {
      let payload = {
        freightCostTn: this.newFreightCostTn,
      };
      if (
        !this.isDraft &&
        this.newActualStatus !== this.isActualFreightCostTn
      ) {
        payload.isActualFreightCostTn = this.newActualStatus;
      }

      this.editMode = false;

      this.$emit("saveClick", payload);
    },
    editClick() {
      this.newFreightCostTn = this.freightCostTn;
      this.newActualStatus = this.isActualFreightCostTn;
      this.editMode = true;
    },
    backClick() {
      this.editMode = false;
      this.deleteMode = false;
    },
  },
  computed: {
    isActual() {
      // true
      return this.isActualFreightCostTn === true;
    },
    isProyected() {
      // null it means proyected
      return this.isActualFreightCostTn === null;
    },
    producedTons() {
      return roundToX(this.assignedHectares * this.dynamicYield * (1 / 10), 2);
    },
    ownTons() {
      return roundToX(
        this.producedTons *
        (1 - (this.variableShareCroppingValue * Number(this.isVariableSharecroppingHandedOver))
          - (this.fixedShareCroppingValue * Number(this.isFixedSharecroppingHandedOver))),
        2
      );
    },
    subTotal() {
      if (this.editMode) {
        return roundToX(this.ownTons * this.newFreightCostTn, 2).toLocaleString(
          "en-US",
          { timeZone: "America/Argentina/Buenos_Aires" }
        );
      } else {
        return roundToX(this.ownTons * this.freightCostTn, 2).toLocaleString(
          "en-US",
          { timeZone: "America/Argentina/Buenos_Aires" }
        );
      }
    },
  },

  async mounted() { },
  data() {
    return {
      actualsItems: HARVEST_FREIGHT_ACTUAL_ITEMS,
      select: null,
      newActualStatus: null,
      editMode: false,
      deleteMode: false,
      newFreightCostTn: 0,
    };
  },
};
</script>
